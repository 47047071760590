import { Card, Grid, Typography } from '@mui/material';
import { useState, useEffect, useContext, useCallback } from 'react';
import { getStats } from '../../apis/green';
import { StatsContext } from '../../context/statsContext.jsx';
import theme from '../../theme';

const HomeStatsCard = () => {
  const statsContext = useContext(StatsContext);
  const [stats, setStats] = useState([
    {
      title: 'EV Fleet (%)',
      stat: 0,
    },
    {
      title: 'Electric Deliveries (YTD)',
      stat: 0,
    },
    {
      title: 'CO2 saved from electric deliveries (tonnes) (YTD) *',
      stat: 0,
    },
    {
      title: 'PickUp Point Locations',
      stat: 0,
    },
    {
      title: 'Tonnes of waste recycled (YTD)',
      stat: 0,
    },
    {
      title: 'tCO2e saved using HVO (YTD) *',
      stat: 0,
    },
    {
      title: 'Power Generated from\nSolar Panels (kWh) (YTD)',
      stat: 0,
    },
    {
      title: 'Trees Planted Go\nZero be a Hero (YTD)',
      stat: 0,
    },
  ]);

  const getGreenStats = useCallback(async () => {
    const contextStats = statsContext.stats;
    const setContextStats = statsContext.setStats;
    if (!contextStats) {
      const newStats = await getStats();
      setContextStats(newStats);
      setStats([
        {
          title: 'EV Fleet (%)',
          stat: newStats.fleet_ev.toLocaleString(),
        },
        {
          title: 'Electric Deliveries (YTD)',
          stat: newStats.green_deliveries.toLocaleString(),
        },
        {
          title: 'CO2 saved from electric deliveries (tonnes) (YTD) *',
          stat: newStats.green_deliveries_co2_saved.toLocaleString(),
        },
        {
          title: 'PickUp Point Locations',
          stat: newStats.pickup_point_locations.toLocaleString(),
        },
        {
          title: 'HVO utilisation across our HGV fleet (YTD)',
          stat: newStats.hgv_on_hvo.toLocaleString(),
        },
        {
          title: 'tCO2e saved using HVO (YTD) *',
          stat: newStats.tco_by_hvo.toLocaleString(),
        },
        {
          title: 'Power Generated from\nSolar Panels (kWh) (YTD)',
          stat: newStats.solar_panel_power.toLocaleString(),
        },
        {
          title: 'Trees Planted Go\nZero be a Hero (YTD)',
          stat: newStats.trees_planted.toLocaleString(),
        },
      ]);
    } else {
      setStats([
        {
          title: 'EV Fleet (%)',
          stat: contextStats.fleet_ev.toLocaleString(),
        },
        {
          title: 'Electric Deliveries (YTD)',
          stat: contextStats.green_deliveries.toLocaleString(),
        },
        {
          title: 'CO2 saved from electric deliveries (tonnes) (YTD) *',
          stat: contextStats.green_deliveries_co2_saved.toLocaleString(),
        },
        {
          title: 'PickUp Point Locations',
          stat: contextStats.pickup_point_locations.toLocaleString(),
        },
        {
          title: 'HVO utilisation across our HGV fleet (YTD)',
          stat: contextStats.hgv_on_hvo.toLocaleString(),
        },
        {
          title: 'tCO2e saved using HVO (YTD) *',
          stat: contextStats.tco_by_hvo.toLocaleString(),
        },
        {
          title: 'Power Generated from\nSolar Panels (kWh) (YTD)',
          stat: contextStats.solar_panel_power.toLocaleString(),
        },
        {
          title: 'Trees Planted Go\nZero be a Hero (YTD)',
          stat: contextStats.trees_planted.toLocaleString(),
        },
      ]);
    }
  }, [statsContext]);

  useEffect(() => {
    getGreenStats();
  }, [getGreenStats]);

  return (
    <Card
      sx={{
        boxShadow: '0px 36px 142px rgba(56, 90, 79, 0.15)',
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        alignItems="flex-end"
        sx={{ py: 4, px: 2 }}
      >
        {stats?.map(stat => (
          <Grid item key={stat.title} md={3} xs={12}>
            <Typography
              variant="h6"
              color={theme.palette.primary.accentCore}
              sx={{ lineHeight: '1.2' }}
            >
              {stat.title.includes('\n')
                ? stat.title.split('\n').map(title => (
                    <p
                      key={title}
                      style={{
                        marginBottom: 0,
                      }}
                    >
                      {' '}
                      {title}{' '}
                    </p>
                  ))
                : stat.title}
            </Typography>
            <Typography
              variant="h3Light"
              color={theme.palette.primary.core}
              sx={{
                textDecoration: 'underline',
                textDecorationColor: theme.palette.primary.accentCore,
                textDecorationThickness: 1,
                textUnderlineOffset: 18,
                pb: 2,
                fontSize: '28px',
              }}
            >
              {stat.stat}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default HomeStatsCard;
